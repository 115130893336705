<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                <h5 class="mt-2 mb-0">Estudiantes Nuevos</h5>
              </div>
              <div class="col">
                <ul class="nav justify-content-end">
                  <li class="nav-item">
                    <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                    <b>Filas por p&aacute;gina</b>
                  </li>
                  <li class="nav-item">
                    <select class="form-control" @change="sizeChange($event)"
                            v-model="filasPorPagina">
                      <option v-for="option in sizeoptions" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-dark" type="button"
                            @click="borrarFiltroBtnOnClick()">
                      <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                      Quitar Filtro
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-primary" data-html="true"
                            data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"
                            @click="nuevoBtnOnClick()">
                      <font-awesome-icon icon="fa-plus"/>
                      Nuevo
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-success" data-html="true"
                                               data-toggle="tooltip"
                                               title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                                               type="button"
                                               @click="excelBtnOnClick()">
                    <font-awesome-icon icon="fa-file-excel"/>
                    Exportar a Excel
                  </button>
                  </li>
                  <li class="nav-item">
                    <CDropdown
                        inNav
                        class="c-header-nav-items"
                        placement="bottom-end"
                        add-menu-classes="pt-0"
                        :caret="false"
                    >
                      <template #toggler>
                        <CHeaderNavLink title="Ver videos de ayuda">
                          <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                        </CHeaderNavLink>
                      </template>
                      <CDropdownItem >
                        <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-hoja-ruta.mp4')"
                           class="text-info" title="Ver video de ayuda">
                          <font-awesome-icon icon="fas fa-video" />
                          Ayuda (Hoja ruta)
                        </a>
                      </CDropdownItem>
                      <CDropdownItem >
                        <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-eliminar-nuevo.mp4')"
                           class="text text-danger" title="Ver video de ayuda">
                          <font-awesome-icon icon="fas fa-video" />
                          Ayuda (Eliminar)
                        </a>
                      </CDropdownItem>
                    </CDropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body p-0 pr-1 pe-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="darkModal"
        size="xl"
    >
      <template #body-wrapper>
        <div class="modal-body p-0 m-0">
          <embed id="reporteFrame" height="550" src="" type="application/pdf"
                 width="100%">
        </div>
      </template>
      <template #header>
        <h6 class="modal-title">
          <a id="reporteDescargarBtn" download="download" href="">Descargar el documento</a>
        </h6>
        <CButtonClose @click="darkModal = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '6%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '10%',
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '12%',
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Gestion', datafield: "gestion", width: '5%', cellsalign: 'center',
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Gestion"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '4%', cellsalign: 'center',hidden: true,
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return 'text-danger';
            }
            else return '';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: '', width: '5%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            let html = '';
            if(rowdata.estado==='HABILITADO') {
              html = '<a class="btn btn-block btn-primary ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.inscripcion_id + '/' + rowdata.codigo_alumno + '/editar" title="Editar inscripcion de alumno nuevo">Editar</a>';
            }else{
              html=''
            }
            return html;
          }, filterable: false, sortable: false
        },
        {
          text: '',
          datafield: 'documentos',
          width: '10%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            let html = '';
            if(rowdata.estado==='HABILITADO') {
              html = '<a class="btn btn-block btn-success ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.codigo_alumno + '/documentos" title="Recibir Documentos (Hoja de ruta)">Documentos</a>';
            }
            return html;
          },
          filterable: false,
          sortable: false,
        },
        {
          text: '',
          datafield: 'compromisos',
          width: '11%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            let html = '';
            if(rowdata.estado==='HABILITADO') {
              if (rowdata.inscripcion_id > 0) {
                html = '<a class="btn btn-block btn-secondary ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.inscripcion_id + '/' + rowdata.codigo_alumno + '/compromisos" title="Compromisos para entrega de Documentos">Compromisos</a>';
              }
            }
            return html;
          },
          filterable: false,
          sortable: false
        },
        {
          text: '',
          datafield: 'contratos',
          width: '8%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            let html = '';
            if(rowdata.estado==='HABILITADO') {
              if (rowdata.inscripcion_id > 0) {
                html = '<a class="btn btn-block btn-warning ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.inscripcion_id + '/' + rowdata.codigo_alumno + '/' + rowdata.gestion + '/contrato" title="Imprimir contrato">Contrato</a>';
              }
            }
            return html;
          },
          filterable: false,
          sortable: false
        },
        {
          text: "", datafield: 'hojaruta', width: '6%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return '';
            }
            else return 'btn-green';
          },
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.inscripcion_id > 0 && rowdata.estado === 'HABILITADO') ? "Hoja Ruta" : "...");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.estado==='HABILITADO' && rowData.inscripcion_id > 0) {
              modalPdf.$refs.alert.show('Descargando documento');
              axios.get(
                  address + '/api/inscripcion/' + rowData.inscripcion_id + '/hojarutapdf?token=' + localStorage.getItem("api_token"),
                  {responseType: 'blob'}
              )
                  .then((response) => {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
                  })
                  .catch(function (error) {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                  });
            }
          }
        },
        {
          text: "", datafield: 'eliminar', width: '7%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname:function (row, columnfield, value) {
            let rowdata = modalPdf.$refs.gridSystem.getrowdata(row);
            if(rowdata.estado!=='HABILITADO') {
              return '';
            }
            else return 'btn-red';
          },
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            if(rowdata.estado==='HABILITADO') {
              return 'Eliminar';
            }else{
              return 'Eliminado';
            }
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.inscripcion_id > 0) {

            }
            if (rowData.estado==='HABILITADO') {
              modalPdf.$refs.confirm.confirm('¿Esta sugur@ de eliminar el registro?', '', function () {
                modalPdf.$refs.alert.show('Eliminando registro');
                axios.get(
                    address + '/api/inscripcion/' + rowData.inscripcion_id + '/eliminar?token=' + localStorage.getItem("api_token")
                )
                    .then((response) => {
                      modalPdf.$refs.alert.hide();
                      modalPdf.$refs.mensajeToast.makeToast('', 'Eliminado satisfactoriamente.', 'success');
                      modalPdf.$refs.gridSystem.updatebounddata();
                    })
                    .catch(function (error) {
                      modalPdf.$refs.alert.hide();
                      modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                    });
              },function () {
                
              })
            }else{
              modalPdf.$refs.mensajeToast.makeToast('',"Este registro esta eliminado, no se puede modificar", 'danger');
            }
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'inscripcion_id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'documentos', type: "string"},
        {name: 'compromisos', type: "string"},
        {name: 'contratos', type: "string"},
        {name: 'hojaruta', type: "string"},
        {name: 'editar', type: "string"},
        {name: 'eliminar', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'codigo_alumno',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/inscripcion/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    modalPdf.showHideColumn('documentos')
    modalPdf.showHideColumn('compromisos');
    modalPdf.showHideColumn('contratos');
    modalPdf.showHideColumn('hojaruta');
  },
}
</script>
<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>